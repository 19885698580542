import makeStyles from '@mui/styles/makeStyles';
import { darken } from '@mui/material';
import { ThemeTypes } from 'components';

type Props = {
    bold: boolean;
    onlyCodes?: boolean;
};
const useStyles = makeStyles<ThemeTypes.Theme, Props>((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.spacing(2),

        [theme.breakpoints.down('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            width: '100%'
        },
        [theme.breakpoints.down(350)]: {
            gridTemplateColumns: (props) =>
                props.onlyCodes ? 'repeat(2, minmax(0, 1fr))' : 'repeat(1, minmax(0, 1fr))'
        }
    },
    endPositionContainer: {
        '& > button': {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        }
    },
    popoverButton: {
        fontSize: 14,
        padding: theme.spacing(1, 2) + ' !important',
        [theme.breakpoints.down('md')]: {
            gridColumn: (props) => (props.onlyCodes ? 'span 3' : 'span 2')
        },
        [theme.breakpoints.down(350)]: {
            gridColumn: (props) => (props.onlyCodes ? 'span 2' : 'span 1')
        }
    },
    topicsContainer: {
        backgroundColor: 'white',
        position: 'relative'
    },
    paperContainer: {
        width: '80%',
        padding: 0,
        backgroundColor: 'white',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.6)',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    topicItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        backgroundColor: 'transparent',
        paddingBlock: '5px',
        paddingInline: '12px',
        fontWeight: (props) => (props.bold ? 600 : 400),
        lineHeight: '20px',
        borderRadius: '8px',
        border: '1px solid ' + theme.palette.body.light,
        color: theme.palette.primary.main,
        fontFamily: 'Roboto',
        cursor: 'pointer',
        userSelect: 'none',
        hyphens: 'auto',
        transition: 'background-color 0.1s',
        boxSizing: 'border-box',

        '&:hover': {
            backgroundColor: darken('#F0F3F6', 0.04)
        },

        '& span': {
            textAlign: 'left',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            boxOrient: 'vertical'
        }
    },
    topicItemIcon: {
        marginInlineStart: '-4px',
        flexShrink: 0
    },
    activeTopicItem: {
        backgroundColor: theme.palette.secondary[100],
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        cursor: 'initial',
        '&:hover': {
            backgroundColor: theme.palette.secondary[100]
        }
    }
}));

export default useStyles;
