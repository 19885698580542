import React from 'react';
import classNames from 'classnames';
import { ColoredIcon } from 'common';
import useStyles from './TopicSelector.styles';

type TopicItemProps = React.PropsWithChildren<{
    isActive: boolean;
    iconUrl: string | null | undefined;
    buttonClassName?: string;
    iconColor: string | null;
    onClick: () => void;
}>;

export const TopicItem = ({
    isActive,
    children,
    onClick,
    iconUrl,
    iconColor,
    buttonClassName
}: TopicItemProps) => {
    const classes = useStyles({ bold: true });

    return (
        <button
            className={classNames(classes.topicItem, buttonClassName, {
                [classes.activeTopicItem as string]: isActive
            })}
            onClick={() => {
                onClick();
            }}
        >
            {iconUrl && (
                <ColoredIcon
                    width={32}
                    height={32}
                    url={iconUrl}
                    color={iconColor}
                    className={classes.topicItemIcon}
                />
            )}
            {children}
        </button>
    );
};
