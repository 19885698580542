import React from 'react';
import { Typography, Icon } from 'components';
import { makeStyles } from '@mui/styles';
import { filterAlphabetItems, getSortedItemsAndAlphabets } from './HorizontalSelectAllItems.utils';
import { ColoredIcon, NextLink } from 'common';
import { Divider } from '@mui/material';

const useDialogStyles = makeStyles(
    (theme) => ({
        alphabetsContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
            marginLeft: theme.spacing(-1.5),
            marginTop: theme.spacing(-3),
            [theme.breakpoints.up('sm')]: {
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
            },
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
            }
        },
        alphabet: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '24px',
            margin: theme.spacing(3, 0, 1, 1.5)
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1.5),
            color: theme.palette.common.black,
            cursor: 'pointer',
            transition: 'background-color 0.1s',
            '&:hover': {
                backgroundColor: `${theme.palette.common.black}0A`,
                borderRadius: 6,
                '&>div': {
                    display: 'block'
                }
            }
        },
        itemIcon: {
            display: 'none',
            margin: theme.spacing('auto', 1, 'auto', 'auto')
        },
        divider: {
            margin: theme.spacing(2, 4)
        },
        topicIcon: {
            marginRight: theme.spacing(2)
        }
    }),
    { name: 'HorizontalSelect-Dialog', index: 10 }
);

export type HorizontalSelectItem = {
    id: string;
    title: string;
    iconUrl?: string;
    iconColor?: string;
    href: string;
    onClick?: (topic: string) => void;
};

type AllItemsProps = {
    items: Array<HorizontalSelectItem>;
};

export const HorizontalSelectAllItems = ({ items }: AllItemsProps) => {
    const classes = useDialogStyles();
    const { sortedItems, alphabets } = getSortedItemsAndAlphabets(items);

    return (
        <div className={classes.alphabetsContainer}>
            {alphabets.map((alphabet) => (
                <div key={alphabet}>
                    <Typography title={alphabet} className={classes.alphabet} />
                    {sortedItems.filter(filterAlphabetItems(alphabet)).map((item) => (
                        <NextLink
                            className={classes.item}
                            key={item.id}
                            href={item.href}
                            onClick={() => item.onClick?.(item.id)}
                        >
                            {item.iconUrl && item.iconColor && (
                                <ColoredIcon
                                    width={32}
                                    height={32}
                                    className={classes.topicIcon}
                                    url={item.iconUrl}
                                    color={item.iconColor}
                                />
                            )}
                            <Typography title={item.title} variant="textXSmall" />
                            <Icon icon="caretRight" className={classes.itemIcon} />
                        </NextLink>
                    ))}
                    <Divider className={classes.divider} />
                </div>
            ))}
        </div>
    );
};
